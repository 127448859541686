import styled from 'styled-components';

import ROUTES from 'config/routes';
import { DEVICE } from 'config/theme';
import Trans from 'next-translate/Trans';
import Image from 'next/image';
import { LinkAButton } from 'shared/components/LinkButton';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import useTranslation from 'shared/hooks/useTranslation';

const Section = styled.section`
  background-color: #fc7e00;
  height: 30rem;
  position: relative;

  @media ${DEVICE.MD} {
    height: 50rem;
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Text = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  height: 100%;
  justify-content: center;
  padding: 0 4rem;
  position: absolute;
  z-index: 10;

  @media (max-width: 1598px) {
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  @media ${DEVICE.MD} {
    gap: 5.5rem;
    padding: 0 10rem;
  }

  @media (min-width: 1599px) {
    max-width: 90rem;
  }

  h2 {
    color: white;
    font-size: 3rem;
    font-weight: 800;
    line-height: 4.2rem;

    @media ${DEVICE.MD} {
      font-size: 6rem;
      line-height: 8.2rem;
    }
  }
`;

const StyledLinkButton = styled(LinkAButton)`
  background-color: white;
  color: black;
  cursor: pointer;
  margin: 0;
  width: fit-content;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
  }
`;

function SimpleDecision(): JSX.Element {
  const { t } = useTranslation('homePage');

  const { isMatch: isDesktop, mediaLoaded } = useMediaQuery(DEVICE.XXL);

  return (
    <Section>
      {isDesktop && mediaLoaded ? (
        <ImageWrapper>
          <Image
            src="/img/home/v2/simple-decision.png"
            alt="simple decision"
            layout="fill"
            objectFit="contain"
            objectPosition="right top"
          />
        </ImageWrapper>
      ) : null}
      <Text>
        <h2>
          <Trans
            i18nKey="homePage:simpleDecisionSection.simpleDecision"
            components={[<br key="enter" />]}
            fallback="homePageEN:simpleDecisionSection.simpleDecision"
          />
        </h2>
        <StyledLinkButton
          onClick={
            process.env.NEXT_PUBLIC_APP_ENV === 'prod'
              ? "ga('send', 'event', 'button - redirect to register', 'click', 'page - homepage', 'section - simple decision');"
              : ''
          }
          href={ROUTES.REGISTER}
        >
          {t('navbar.freeTrial', 'common')}
        </StyledLinkButton>
      </Text>
    </Section>
  );
}

export default SimpleDecision;
